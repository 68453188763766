import React from 'react';      
var largeImg = false;

// Function to increase image size
function enlargeImg(e) {
 // Set image size to 1.5 times original 
 if(!largeImg) {
  e.target.setAttribute("style", "z-index: 1; max-height: 100%;");
  e.target.style.transform = "scale(1.5)";
  e.target.style.transition = "transform 0.25s ease";
  largeImg = true;
 } else {
  e.target.setAttribute("style", "z-index: 0; width: 90%; max-width: 450px;");
  e.target.style.transform = "scale(1)";
  e.target.style.transition = "transform 0.25s ease";
  largeImg = false;
 }

}

const CharacterCard = (props) => {
  return (
      <div className= "CharacterCard__Container">
          <h2 className="Characters__CharacterName">{props.CharacterName}</h2>
          <img
          className="character-image"
          id = {props.IdName}
          src= {props.link}
          alt={props.CharacterName + " posing"}
          onClick= {enlargeImg}
        />
    </div>
    
    
  );
};
  
export default CharacterCard;