import React from 'react';
import SlideShowComponent from './SlideshowComponent';
import Footer from '../Footer';

const Home = () => {
  return (
    <div>
      <div id = "Home__DjDaddyOHomeImage">   
      
      <img
          id = "Home__DjDaddyOImage"
          src= "/images/default-image.jpg"
          alt="First slide"
        />
      </div>

      <h2 className="Pictures__Header">Weddings, Kids Parties, Sweet 16 & More!</h2>

      <SlideShowComponent image1 ="/images/prom-night.jpg"
        image2 = "/images/boys-prom-night.jpg"
        image3 = "/images/paw-patrol.jpg"
      />

      <h2 id = "Home__PopularPackagesHeader">Popular Packages</h2>
      <div className="PopularPackagesSection">
          
          <div className="PopuarPackages__Container">
            <div className="PopularPackages__Label">
              Dj Entertainment: 
            </div>
            <div className="PopularPackages__Details">
              <div className="PopularPackages__DetailSection">
                Enjoy 2-5 hours of uninterrupted music catered to your specific wants. 
              </div>
              <div className="PopularPackages__DetailSection">
              <ul className="PopularPackages__List">
                <li>
                  Up to 5 hours of music.
                </li>
                <li>
                  Lights directed onto the dancefloor.
                </li>
                <li>
                  Introductions if wanted / necessary for special occassions such as newly wed introductions.
                </li>
                <li>
                  Glowsticks.
                </li>
              </ul>
              </div>
              <div className="PopularPackages__DetailSection">
                Starting at $250.00
              </div>
            </div>
          </div>

          <div className="PopuarPackages__Container">
            <div className="PopularPackages__Label">
              Character Meet and Greet: 
            </div>
            <div className="PopularPackages__Details">
              <div className="PopularPackages__DetailSection">
                Enjoy time with your favorite character at your party. Whether its planned or a suprise, 
                you and your little ones will love the interaction!
              </div>
              <div className="PopularPackages__DetailSection">
                <ul className="PopularPackages__List">
                <li>
                  Photos with the character.
                </li>
                <li>
                  Games with the character.
                </li>
                <li>
                  Songs and dance with the character.
                </li>
              </ul>
              </div>
              <div className="PopularPackages__DetailSection">
                Starting at $150.00
              </div>
            </div>
          </div>

          <div className="PopuarPackages__Container">
            <div className="PopularPackages__Label">
              Kids Entertainment and Meet and Greet: 
            </div>
            <div className="PopularPackages__Details">
              <div className="PopularPackages__DetailSection">
                Enjoy hours and uninterrupted music throughout your kids event with a special guest appearance. 
              </div>
              <div className="PopularPackages__DetailSection">
              <ul className="PopularPackages__List">
                <li>
                  1-4 hours of continous music.
                </li>
                <li>
                  Meet and Greet with character(s) of your choosing.
                </li>
                <li>
                  Kids games including musical chairs, child tatoos, limbo, parachute and more!
                </li>
              </ul>
              </div>
              <div className="PopularPackages__DetailSection">
                Starting at $250.00
              </div>
            </div>
          </div>
      </div>
      <Footer/>
    </div>
  );
};
  
export default Home;