import SlideShowComponent from "./SlideshowComponent";
import React from "react";
import Footer from '../Footer';

  class FormalOccasionsComponent extends React.Component {
    render() {
      return (
      <div>    
    <h2 className="Pictures__Header">Formal Occasions</h2>

    <SlideShowComponent image1 ="/images/prom-night.jpg"
    image2 = "/images/boys-prom-night.jpg"
    image3 = "/images/dancing-night.jpg"
    />
      </div>);
    }
  }

  class KidsPartiesComponent extends React.Component {
    render() {
        return (
        <div>
            <h2 className="Pictures__Header">Kids Parties</h2>

            <SlideShowComponent image1= "/images/paw-patrol.jpg"
            image2 = "/images/coco-party.jpg"
            image3 = "/images/bunny-party.jpg"
            />
        </div>
        );
    }
  }
  
  class Pictures extends React.Component {
    constructor() {
      super();
      this.state = {
        kidsPartyVisible: false,
        formalVisible: true
      }
    }
  
    render() {
      return (
        <div>

    <select id = "dropdown"
        onChange = {(e) => this.StatusChanged(e)}>
        <option value = "formalOccasion">Formal Occasion</option>
        <option value = "kidsParties">Kids Parties</option>    
    </select>
        {
          this.state.formalVisible
            ? <FormalOccasionsComponent/>
            : null
        }
        {
          this.state.kidsPartyVisible
            ? <KidsPartiesComponent/>
            : null
        }

    <div className="Pictures__PersonalTesimonies">
      <h2 className= "Pictures__Header">Personal Testimonies</h2>
        <div id="Testimonies">
        
            <div id = "Pictures__TestimoniesLeft">
            <div className = "Pictures__TestimonyContainer">
            <div className = "Pictures__TestimonyImageDiv">
            <img
                className="Pictures__TestimonyImage"
                src="/images/person-message-icon.png"
                alt="User Icon"
              />
            </div>
            <div className = "Pictures__Testimony">
                "If anyone wants to make there kids birthday super special I definitely recommend DJ DADDY O ENT. They have awesome costume characters and will visit your home all while social distancing."
                <div>~ BoyMom Creations</div>
              </div>
            </div>
            </div>

            <div id = "Pictures__TestimoniesRight">
                <div className = "Pictures__TestimonyContainer">
                  <div className= "Pictures__TestimonyImageDiv">
                  <img
                    className="Pictures__TestimonyImage"
                    src="/images/person-message-icon.png"
                    alt="User Icon"
                  />
                  </div>
                  <div className = "Pictures__Testimony">
                    "DJ DADDY O ENT brings the entertainment at very affordable prices.  We recommend them highly."
                    <div>~ Momee Friends of Long Island</div>
                  </div>
                </div>

                <div className = "Pictures__TestimonyContainer">
                  <div className= "Pictures__TestimonyImageDiv">
                  <img
                      className="Pictures__TestimonyImage"
                      src="/images/person-message-icon.png"
                      alt="User Icon"
                    />
                  </div>
                  <div className = "Pictures__Testimony">
                  "Thank you DJ DADDY O ENT , Liz and all our paw patrol friends!! Amazing time seeing all the kids faces!!!"
                  <div>~ Debbie L.</div>
                  </div>
                </div>

            </div>
        </div>
    </div>
    <Footer/>
        </div>
      )
    }

    StatusChanged(e) {
        if (e.target.value === "kidsParties"){
            this.setState(prevState => ({ kidsPartyVisible: true }));
            this.setState(prevState => ({ formalVisible: false }));
          } else if (e.target.value === "formalOccasion") {
            this.setState(prevState => ({ formalVisible: true }));
            this.setState(prevState => ({ kidsPartyVisible: false }));
          }else {
            this.setState(prevState => ({ kidsPartyVisible: false }));
            this.setState(prevState => ({ formalVisible: false }));
          }
    }
  };
  
  export default Pictures;