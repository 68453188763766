import React from "react";
import Footer from '../Footer';
  
const Services = () => {
  return (
    <div>

      <div id="Services__Container">

        <div className = "Services__ServiceContainer">
          <div className = "Services__ServiceImg">
            <img
            id="Services__DancingImage"
            src= "/images/services/dancing.jpg"
            alt="People dancing on the dance floor in the dark with colorful lights on them"
            />
          </div>
          <div className= "Services__ServiceInformation">
            <h2>DJ Entertainment</h2>
            <p>Looking to throw the best party on Long Island? Whether you want an elegant wedding, a fun kids party, 
              or anything in between Dj Daddy O has the perfect playlist for you! 
            </p>
            <i className = "Services__PriceLabel">Starting at $250.00</i>
          </div>
        </div>

        <div className = "Services__ServiceContainerReverse">
          <div className = "Services__ServiceImgReverse">
            <img
            id="Services__KidsEntertainmentImage"
            src= "/images/services/olaf-playing.jpg"
            alt="Olaf holding a foam tube for kids to play limbo"
            />
          </div>
          <div className= "Services__ServiceInformationReverse">
            <h2>Kids Entertainment</h2>
            <p>Want to throw your little one a party they will remember? Dj Daddy O and Mommy O 
              will entertain your kids with fun activities and playful music including playing parachute,
              tatoos and so much more depending on the package you choose.
            </p>
            <i className = "Services__PriceLabel">Starting at $250.00</i>
          </div>
        </div>

        <div className = "Services__ServiceContainer">
          <div className = "Services__ServiceImg">
            <img
            id="Services__MeetForkyImage"
            src= "/images/services/Meet-and-greet-characters.png"
            alt="Celebration of many characters"
            />
          </div>
          <div className= "Services__ServiceInformation">
            <h2>Character Meet and Greets</h2>
            <p>Have a themed party you want to make the best themed party ever? Elmo, Mickey Mouse, a princess,
              and paw patrol are only some of the <a href ="/characters">characters</a> that we have available for hire!</p>
            <i className = "Services__PriceLabel">Starting at $150.00</i>
          </div>
        </div>

        <div className = "Services__ServiceContainerReverse">
          <div className = "Services__ServiceImgReverse">
            <img
            id="Services__CottonCandyImage"
            src= "/images/cotton-candy-machine.jpg"
            alt="Cotton candy on a cone"
            />
          </div>
          <div className= "Services__ServiceInformationReverse">
            <h2>Cotton Candy Machine</h2>
            <p>Special occasions, kids parties, sweet 16's or just a sweet tooth, cotton 
              candy is the perfect choice! Wait in line for some of this sugary sweet treat while 
              our workers prepare it for everyone. We have vanilla, strawberry or bubblegum flavors for you to choose from.
            </p>
            <i className = "Services__PriceLabel"> Starting at $100.00</i>
          </div>
        </div>

        <div className = "Services__ServiceContainer">
          <div className = "Services__ServiceImg">
            <img
            id="Services__SnowConeMachineImage"
            src= "/images/snow-cone-machine.jpg"
            alt="Snow Cone machine with snow cone in the employees hand."
            />
          </div>
          <div className= "Services__ServiceInformation">
            <h2>Snow Cone Machine</h2>
            <p>Feeling chilly? Wait in line to enjoy a sweet snow cone prepared by our workers! We have grape, cherry, blueberry,
               and orange flavored syrup for you to savor!</p>
            <i className = "Services__PriceLabel">Starting at $100.00</i>
          </div>
        </div>

        <div className = "Services__ServiceContainerReverse">
          <div className = "Services__ServiceImgReverse">
            <img
            id="Services__PopcornImage"
            src= "/images/popcorn-machine.jpg"
            alt="Popcorn machine with popcorn."
            />
          </div>
          <div className= "Services__ServiceInformationReverse">
            <h2>Popcorn Machine</h2>
            <p>Lets get the party popping! Wait in line for some yummy popcorn while 
              our workers prepare it for everyone to enjoy.
            </p>
            <i className = "Services__PriceLabel"> Starting at $100.00</i>
          </div>
        </div>

      </div>
      <Footer/>
    </div>
  );
};
  
export default Services;