import React from 'react';
import Footer from '../Footer';
import CharacterCard from './character-card';

const Characters = () => {
  return (
    <div>
      <h1 className="Characters__Header">Available Characters</h1>

      <div id = "Characters__Container">
        <CharacterCard IdName = "PawPatrol" CharacterName = "Paw Patrol" link = "/images/paw-patrol.jpg"></CharacterCard>
        <CharacterCard IdName = "GroguandStormtrooper" CharacterName = "Grogu and Stormtrooper" link = "/images/grogu-stormtrooper.png"></CharacterCard>
        <CharacterCard IdName = "EasterBunnies" CharacterName = "Easter Bunnies" link = "/images/bunnies.jpg"></CharacterCard>
        <CharacterCard IdName = "Elfonashelf" CharacterName = "Elf on a shelf" link = "/images/elf-on-shelf.png"></CharacterCard>
        <CharacterCard IdName = "ElmoandAbby" CharacterName = "Elmo and Abby" link = "/images/elmo-and-aby.jpg"></CharacterCard>
      </div>
      <Footer/>
    </div>
    
  );
};
  
export default Characters;