import Carousel from 'react-bootstrap/Carousel';
function SlideShowComponent(images) {
  return (
      <Carousel id="Pictures__Carousel">
      <Carousel.Item interval={1000}>
        <img
          className="d-block w-100"
          src= {`${images.image1}`}
          alt="First slide"
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={1000}>
        <img
          className="d-block w-100"
          src= {`${images.image2}`}
          alt="Second slide"
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={1000}>
        <img
          className="d-block w-100"
          src= {`${images.image3}`}
          alt="Third slide"
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    );
  }
  export default SlideShowComponent;