import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route}
    from 'react-router-dom';
import DjNavbar from "./components/index.js";
import Home from './components/pages';
import About from './components/pages/about';
import Pictures from './components/pages/pictures';
import Contact from './components/pages/contact';
import Services from './components/pages/services';
import Characters from './components/pages/characters';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
return (
<Router>
    <DjNavbar />
    <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/services' element={<Services />} />
        <Route path='/about' element={<About/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='/pictures' element={<Pictures/>} />
        <Route path='/characters' element={<Characters/>} />
    </Routes>
</Router>
);
}
  
export default App;