import React from 'react';
import Footer from '../Footer';
  
const Contact = () => {
  return (
    <div>
      <h1>Contact Us</h1>
      <Footer/>
    </div>
    
  );
};
  
export default Contact;