import {FaInstagram} from 'react-icons/fa';
import {FaFacebook} from 'react-icons/fa';
import {FaTiktok} from 'react-icons/fa';
function Footer() {
  return (
    <div className= "Footer">
      <h2 className='Footer__HeaderText'>Connect with us!</h2>
        <div className="Footer__SocialMediaIcons">
            <a rel="noopener noreferrer" className = "Footer__SocialMediIcon" target = "_blank" href='https://www.facebook.com/profile.php?id=100064639335999'><FaFacebook/></a>
            <a rel="noopener noreferrer" className = "Footer__SocialMediIcon" target = "_blank" href='https://www.instagram.com/djdaddyoent/'><FaInstagram/></a>
            <a rel="noopener noreferrer" className = "Footer__SocialMediIcon" target = "_blank" href='https://www.tiktok.com/@djdaddyo?_t=8XwNObrDEg2&_r=1'><FaTiktok/></a>
        </div>
        
        <div className="Footer__ContactInfo">
          <h2 className='Footer__HeaderText'>Contact us to book now!</h2>
          <div className="Footer__LinkText">
            <a href="tel:+16312786741">(631) 278-6741</a>
          </div>
          <div className="Footer__LinkText">
            <a href="mailto:dj-daddyo@optonline.net">peterolivieri@djdaddyo.com</a>
          </div>
          
        </div>
    </div>
    );
  }
  export default Footer;