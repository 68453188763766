import React from "react";
import Footer from '../Footer';
  
const About = () => {
  return (
    <div>
      <h1 id = "About__Header">
        About Us
      </h1>
      <div className= "AboutUs__InformationContainer">
        <div id = "AboutUs__DaddyOInformationContainer">        
          <div className="AboutUs__ImageDiv">
            <img
              id="dj-daddy-o-image"
              src= "/images/dj-daddy-o-self-image.jpg"
              alt="Peter Olivieri's face"
            />
            <p className="AboutUs__NameHeader">Peter Olivieri</p>
          </div>
          <div id = "AboutUs__DaddyOInformationText">
            <p>
              Peter Olivieri created this small business in 2005 to bring joy to families
              through music. He loves bringing the party wherever he goes and aims to put
              a smile on everyones face. He is in love with music so much so that he was even on 
              Dance Party USA and has not stopped dancing yet! As a dad and grandpa, he 
              knows what everyone loves and is the perfect person for all of your entertainment needs.
            </p>
          </div>
        </div>
        <div id ="AboutUs__MommyOInformationContainer">
          <div className="AboutUs__ImageDiv">
            <img
              id="mommy-o-image"
              src= "/images/mommy-o-self-image.jpg"
              alt="Elizabeth Olivieri's face"
            />
            <p className="AboutUs__NameHeader">Elizabeth Olivieri</p>
          </div>
          <div id = "AboutUs__MommyOInformationText">
            <p>
              As the business got larger, Elizabeth Olivieri joined the party by helping with all of the
              kid entertainment needs. As a mom and grandma she radiates a glowing energy that children naturally
              gravitate towards. In her free time she loves to craft and spend time with the family. There is nothing
              more satisfying than creating life long memories. So why not let her help you create some of your
              own!
            </p>
          </div>
        </div>
        
      </div>
      <Footer/>
    </div>
  );
};
  
export default About;